import React, { useState, useEffect } from 'react';
import { supabase } from './../supabaseClient';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [formData, setFormData] = useState({ name: '', price: '', Priority:'', rating: 5, mode: 'Offline', category: '', is_popular: false, weeks: '', number_of_seats: '', extra_detail: '' });

  useEffect(() => {
    const fetchCourses = async () => {
      const { data, error } = await supabase
        .from('courses')
        .select('*');
      if (error) {
        console.error('Error fetching courses:', error);
      } else {
        setCourses(data);
      }
    };

    fetchCourses();
  }, []);

  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const paginatedCourses = filteredCourses.slice(startIdx, endIdx);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  const openEditModal = (course) => {
    setCurrentCourse(course);
    setFormData({ ...course });
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  const openDeleteModal = (course) => {
    setCurrentCourse(course);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleAddCourse = async (event) => {
    event.preventDefault();
    const { data, error } = await supabase
      .from('courses')
      .insert([formData]);
    if (error) {
      console.error('Error adding course:', error);
    } else {
      setCourses(prevCourses => [...prevCourses, { ...formData }]);
      closeAddModal();
    }
  };

  const handleEditCourse = async (event) => {
    event.preventDefault();
    const { error } = await supabase
      .from('courses')
      .update(formData)
      .eq('id', currentCourse.id);
    if (error) {
      console.error('Error editing course:', error);
    } else {
      setCourses(prevCourses => prevCourses.map(course =>
        course.id === currentCourse.id ? { ...course, ...formData } : course
      ));
      closeEditModal();
    }
  };

  const handleDeleteCourse = async () => {
    const { error } = await supabase
      .from('courses')
      .delete()
      .eq('id', currentCourse.id);
    if (error) {
      console.error('Error deleting course:', error);
    } else {
      setCourses(prevCourses => prevCourses.filter(course => course.id !== currentCourse.id));
      closeDeleteModal();
    }
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="text-xl font-semibold">Agni</h1>
          <p className="text-gray-500">Welcome back to Agni 👋</p>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            className="px-4 py-2 border border-gray-300 rounded-full"
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 material-icons">
            search
          </span>
        </div>
        <button
          className="px-4 py-2 bg-orange-500 text-white rounded-md"
          onClick={openAddModal}
        >
          Add Course
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-100 border-b">#</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Course name</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Price</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Ratings</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Mode</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Category</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Popular</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Weeks</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Seats</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Extra Detail</th>
            <th className="py-2 px-4 bg-gray-100 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedCourses.map((course, index) => (
            <tr key={course.id}>
              <td className="py-2 px-4 border-b">{startIdx + index + 1}</td>
              <td className="py-2 px-4 border-b">{course.name}</td>
              <td className="py-2 px-4 border-b">{course.price}</td>
              <td className="py-2 px-4 border-b">{'⭐'.repeat(course.rating)}</td>
              <td className="py-2 px-4 border-b">{course.mode}</td>
              <td className="py-2 px-4 border-b">{course.category}</td>
              <td className="py-2 px-4 border-b">{course.is_popular ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4 border-b">{course.weeks}</td>
              <td className="py-2 px-4 border-b">{course.number_of_seats}</td>
              <td className="py-2 px-4 border-b">{course.extra_detail}</td>
              <td className="py-2 px-4 border-b">
                <button
                  className="px-4 py-1 bg-orange-500 text-white rounded-md mr-2"
                  onClick={() => openEditModal(course)}
                >
                  Edit details
                </button>
                <button
                  className="material-icons text-gray-400"
                  onClick={() => openDeleteModal(course)}
                >
                  delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <span className="text-gray-500">
          {`Page ${currentPage} of ${totalPages}`}
        </span>
        <div className="flex space-x-2">
          <button
            className="px-2 py-1 border rounded"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            ❮
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
            <button
              key={page}
              className={`px-2 py-1 border rounded ${page === currentPage ? 'bg-orange-500 text-white' : ''}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          ))}
          <button
            className="px-2 py-1 border rounded"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            ❯
          </button>
        </div>
      </div>
      {/* Add Course Modal */}
      {isAddModalOpen && (
        <div className="fixed  inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Add Course</h2>
            <form onSubmit={handleAddCourse} >
              <input
                type="text"
                placeholder="Course Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="number"
                placeholder="Price"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="number"
                placeholder="Rating (1-5)"
                value={formData.rating}
                onChange={(e) => setFormData({ ...formData, rating: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                min="1"
                max="5"
                required
              />
              <select
                value={formData.mode}
                onChange={(e) => setFormData({ ...formData, mode: e.target.value })}
                className="block w-full p-2 border border-gray-300 rounded mb-4"
                required
              >
                <option value="Offline">Offline</option>
                <option value="Online">Online</option>
              </select>
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className="block w-full p-2 border border-gray-300 rounded mb-4"
                required
              >
                <option value="French">French</option>
                <option value="Spanish">Spanish</option>
                <option value="German">German</option>
                <option value="Sanskrit">Sanskrit</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </select>
              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={formData.is_popular}
                  onChange={(e) => setFormData({ ...formData, is_popular: e.target.checked })}
                  className="mr-2"
                />
                Is Popular
              </label>
              <input
                type="number"
                placeholder="Weeks"
                value={formData.weeks}
                onChange={(e) => setFormData({ ...formData, weeks: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="number"
                placeholder="Number of Seats"
                value={formData.number_of_seats}
                onChange={(e) => setFormData({ ...formData, number_of_seats: e.target.value })}
                className="block w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
               <input
                type="float"
                placeholder="Priority"
                value={formData.Priority}
                onChange={(e) => setFormData({ ...formData, Priority: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <textarea
                placeholder="Extra Detail"
                value={formData.extra_detail}
                onChange={(e) => setFormData({ ...formData, extra_detail: e.target.value })}
                className="block w-full p-2 border border-gray-300 rounded mb-4"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={closeAddModal}
                  className="px-4 py-2 bg-red-500 text-white rounded"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Edit Course Modal */}
      {isEditModalOpen && currentCourse && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Edit Course</h2>
            <form onSubmit={handleEditCourse}>
              <input
                type="text"
                placeholder="Course Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className=" w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="text"
                placeholder="Price"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="number"
                placeholder="Rating (1-5)"
                value={formData.rating}
                onChange={(e) => setFormData({ ...formData, rating: e.target.value })}
                className=" w-full p-2 border border-gray-300 rounded mb-4"
                min="1"
                max="5"
                required
              />
              <select
                value={formData.mode}
                onChange={(e) => setFormData({ ...formData, mode: e.target.value })}
                className=" w-full p-2 border border-gray-300 rounded mb-4"
                required
              >
                <option value="Offline">Offline</option>
                <option value="Online">Online</option>
              </select>
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className="block w-full p-2 border border-gray-300 rounded mb-4"
                required
              >
                <option value="French">French</option>
                <option value="Spanish">Spanish</option>
                <option value="German">German</option>
                <option value="Sanskrit">Sanskrit</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </select>
              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={formData.is_popular}
                  onChange={(e) => setFormData({ ...formData, is_popular: e.target.checked })}
                  className="mr-2"
                />
                Is Popular
              </label>
              <input
                type="number"
                placeholder="Weeks"
                value={formData.weeks}
                onChange={(e) => setFormData({ ...formData, weeks: e.target.value })}
                className=" w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="number"
                placeholder="Number of Seats"
                value={formData.number_of_seats}
                onChange={(e) => setFormData({ ...formData, number_of_seats: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <input
                type="float"
                placeholder="Priority"
                value={formData.Priority}
                onChange={(e) => setFormData({ ...formData, Priority: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <textarea
                placeholder="Extra Detail"
                value={formData.extra_detail}
                onChange={(e) => setFormData({ ...formData, extra_detail: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded mb-4"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeEditModal}
                  className="px-4 py-2 bg-red-500 text-white rounded"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Delete Course Modal */}
      {isDeleteModalOpen && currentCourse && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete the course "{currentCourse.name}"?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleDeleteCourse}
                className="px-4 py-2 bg-red-500 text-white rounded mr-2"
              >
                Delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;

