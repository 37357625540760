import React, { useEffect } from 'react';
import hero from './../img/hero.png';
import mobhero from './../img/mobilehero.png';
import img from './../img/agni.png';
import img1 from "./../img/aartees.png";
import img2 from "./../img/boundless.png";
import img3 from "./../img/medjob.png";
// import img4 from "./../img/interpers.png";
import img5 from "./../img/skywings.png";
import img6 from "./../img/guiders.png";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a default duration
  }, []);

  return (
    <div id="home" className="bg-white lg:py-1">
      <div className="lg:mx-10 mx-3 flex flex-col items-center lg:flex-row lg:items-start justify-between">
        <div className="lg:w-1/2 text-center lg:text-left mb-10 lg:mt-2 lg:mb-0">
          <p className="text-orange-500 lg:text-2xl text-md lg:text-center mb-2" data-aos="fade-up">
            -- YOUR GATEWAY TO GLOBAL COMMUNICATION! --
          </p>
          <h2 className="text-3xl lg:mt-4 text-5xl lg:text-6xl font-bold mb-4" data-aos="fade-up">
            Empower your <span className="text-[#95328b]">future Learn </span>Anywhere
          </h2>
          <p className="text-gray-600 mt-5 lg:text-lg mb-6" data-aos="fade-up">
          <span className="font-bold">Academy of Global and National Integration. </span> 
          Experience top-quality education through interactive methods, comprehensive courses, tailored exam prep, and personalized learning paths. Embrace flexible learning from anywhere in the world and discover your potential to communicate confidently on a global scale.
          </p>
          <div className="flex justify-center mt-11 lg:justify-start space-x-4">
            <Link to="/contact" className="bg-orange-500 text-white lg:text-2xl py-2 px-6 rounded-3xl hover:bg-orange-600 transition duration-300" data-aos="fade-up">
              Get started
            </Link>
            <Link to="/learnmore" className="border border-orange-500 text-orange-500 lg:text-2xl rounded-3xl py-2 px-6 rounded hover:bg-orange-50 transition duration-300" data-aos="fade-up">
              Learn more
            </Link>
          </div>
          <div className="lg:block hidden mt-10">
            <hr className="border-t mt-10 mb-5 border-gray-300" />
          </div>
          <div className="w-full hidden lg:block lg:align-left mt-10">
            <p className="md:text-center lg:text-left lg:text-2xl lg:font-bold text-gray-600 mb-6" data-aos="fade-up">Trusted by Institutions and millions of learners around the world</p>
            <div className="flex mt-15 lg:justify-left flex-wrap gap-4">
        <a href="https://www.instagram.com/_agni.x_/" target="_blank" rel="noopener noreferrer">

            <img src={img} alt="Medical Jobs" className="h-20 w-30 m-2" data-aos="fade-up" /></a>
            <img src={img3} alt="Boundless" className="h-20 w-35  m-2" data-aos="fade-up" />

              <img src={img1} alt="Interpers" className="h-20 w-35 m-2" data-aos="fade-up" />
              <img src={img2} alt="AARTEES" className="h-20 w-35 m-2" data-aos="fade-up" />
              <img src={img6} alt="Guiders" className="h-20 w-30 m-2" data-aos="fade-up" />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 flex flex-col items-center lg:items-end relative">
          <div className="hidden lg:block">
            <img src={hero} alt="Calendar" className='w-[150%] h-[150%]' data-aos="fade-left" />
          </div>
          <div className="block lg:hidden">
            <img src={mobhero} alt="Profiles" className='items-center ml-10' data-aos="fade-right" />
          </div>
        </div>
      </div>
      <div className="mx-10 lg:align-left blog lg:hidden mt-1">
        <p className="md:text-center lg:text-left text-gray-600 mb-6" data-aos="fade-up">Trusted by Institutions and millions of learners around the world</p>
        <div className="flex lg:justify-left justify-between flex-wrap gap-4">
        <a href="https://www.instagram.com/_agni.x_/" target="_blank" rel="noopener noreferrer">
          <img src={img} alt="Medical Jobs" className="h-10" data-aos="fade-up" />
        </a>
          <img src={img3} alt="Boundless" className="h-10" data-aos="fade-up" />
          <img src={img1} alt="Interpers" className="h-10" data-aos="fade-up" />
          <img src={img5} alt="SkyWings" className="h-8" data-aos="fade-up" />
          <img src={img2} alt="AARTEES" className="h-6" data-aos="fade-up" />
          {/* <img src={img4} alt="Medical Jobs" className="h-7" data-aos="fade-up" /> */}
          <img src={img6} alt="Guiders" className="h-6" data-aos="fade-up" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
