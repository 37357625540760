import React, { useRef, useState, useEffect, useCallback } from 'react';
import { supabase } from './../supabaseClient';
import { useNavigate } from 'react-router-dom';

const Course = () => {
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [courses, setCourses] = useState([]);
  const navigator = useNavigate();

  // Fetch course data from Supabase
  useEffect(() => {
    const fetchCourses = async () => {
      const { data, error } = await supabase
        .from('courses')
        .select('*');
      if (error) {
        console.error('Error fetching courses:', error);
      } else {
        const popularCourses = data
          .filter(course => course.is_popular)
          .sort((a, b) => b.Priority - a.Priority); // Sort by priority field
        setCourses(popularCourses);
      }
    };

    fetchCourses();
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX); // scroll-fast
    scrollRef.current.scrollLeft = scrollLeft - walk;
  }, [isDragging, startX, scrollLeft]);

  const handleTouchMove = useCallback((e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX); // scroll-fast
    scrollRef.current.scrollLeft = scrollLeft - walk;
  }, [isDragging, startX, scrollLeft]);

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const toggleDetails = (id) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div id="products" className="lg:mx-20 mx-5 p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Our Most <span className="text-orange-500">Popular Courses</span>
        </h2>
        <a href="/course" className="text-orange-500">
          Explore courses &gt;
        </a>
      </div>
      <div
        className="flex overflow-x-auto scroll-container space-x-4 cursor-grab active:cursor-grabbing"
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          touchAction: 'pan-x',
          scrollSnapType: 'x mandatory', // Enable scroll snap
          overflowX: 'scroll',
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch', // Enable momentum scrolling for iOS
        }}
      >
        {courses.map((course) => (
          <div
            key={course.id}
            className="border rounded-lg p-4 flex-shrink-0 relative"
            style={{
              scrollSnapAlign: 'start', // Snap each item to the start
              width: '250px', // Adjust width as necessary
            }}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="bg-orange-400 text-white rounded-full px-3 py-1 text-sm font-semibold">⭐ {course.rating}</span>
              </div>
            </div>
            <h2 className="mt-2 text-lg font-bold">{course.name}</h2>
            <div className="flex justify-between mt-2">
              <p className="mb-2"><i className="fas fa-calendar-alt"></i> {course.weeks} Weeks </p>
              <p className="mt-1 text-orange-600 font-bold">₹ {course.price}/-</p>
            </div>
            <button
              className="mt-4 bg-orange-500 text-white w-full py-2 px-4 rounded-xl h-15 hover:bg-orange-600"
              onClick={() => {navigator('/contact')}}
            >
              Enquire Now 
            </button>
            <button
              className="mt-2 border border-orange-500 text-orange-500 w-full py-2 px-4 rounded-xl h-15 hover:bg-orange-500 hover:text-white"
              onClick={() => toggleDetails(course.id)}
            >
              {showDetails[course.id] ? 'Hide Details' : 'Show Details'} <i className={`fas fa-chevron-${showDetails[course.id] ? 'up' : 'down'}`}></i>
            </button>
            {showDetails[course.id] && (
              <div className="mt-4 bg-orange-500 text-white p-4 rounded-lg">
                <p className="mb-2"><i className="fas fa-users"></i> {course.number_of_seats} Seats</p>
                <p className="mb-2"><i className="fas fa-book"></i> {course.weeks} Weeks </p>
                {course.category === 'German' &&   <p className="mb-2"><i className="fas fa-calendar-alt"></i> Access to Agni library (6+10) weeks</p>}
                {course.extra_detail && (
                  <p
                    className="mt-2"
                    style={{
                      maxWidth: '35vh', 
                      overflowWrap: 'break-word', 
                      wordBreak: 'break-word'
                    }}
                  >
                    <i className="fas fa-info-circle"></i> {course.extra_detail}
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Course;
