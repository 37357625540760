import React, { useState } from 'react';
import { supabase } from './../supabaseClient';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
    message: '',
    interestedIn: '', // New field
    level: '', // New field
    mode: '' // New field
  });
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus('');

    const { data, error } = await supabase
      .from('responses')
      .insert([formData]);

    setLoading(false);

    if (error) {
      console.error('Error submitting form:', error);
      setStatus('An error occurred. Please try again.');
    } else {
      setStatus('Your message has been sent successfully!');
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        message: '',
        interestedIn: '', // Reset field
        level: '', // Reset field
        mode: '' // Reset field
      });
    }
  };

  return (
    <div className="flex lg:mx-[10%] lg:my-[5%] flex-col items-center justify-center py-8 bg-white">
      <div className="w-full p-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-1/2 md:mr-8">
            <h2 className="text-3xl font-bold lg:text-8xl mb-4">Let's Talk</h2>
            <p className="text-gray-600 lg:text-xl mb-8">
              To request a quote or want to meet up, contact us directly or fill the form and we will get back to you promptly.
            </p>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  placeholder="Enter your first name"
                  onChange={handleChange}
                  required
                  className="p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <input
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  placeholder="Enter your last name"
                  onChange={handleChange}
                  required
                  className="p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Enter your email address"
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="text"
                name="phonenumber"
                value={formData.phonenumber}
                placeholder="Enter your phone number"
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <textarea
                name="message"
                value={formData.message}
                placeholder="Your message"
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                rows="4"
              ></textarea>
              <select
                name="interestedIn"
                value={formData.interestedIn}
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <option value="">Interested in</option>
                <option value="German">German</option>
                <option value="French">French</option>
                <option value="Spanish">Spanish</option>
                <option value="Hindi">Hindi</option>
                <option value="Sanskrit">Sanskrit</option>
              </select>
              <select
                name="level"
                value={formData.level}
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <option value="">Level</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="Exam Prep">Exam Prep</option>
              </select>
              <select
                name="mode"
                value={formData.mode}
                onChange={handleChange}
                required
                className="w-full p-3 border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <option value="">Mode</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </select>
              <button
                type="submit"
                className={`w-full bg-orange-500 text-white p-3 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
            <a
              className="mt-4 bg-orange-500 text-white w-full py-2 px-4 rounded-xl h-15 hover:bg-orange-600 flex items-center justify-center"
              href="tel:+918089063643"
            >
              Enquire Now <i className="fas fa-phone-alt ml-2"></i>
            </a>
            {status && (
              <div className={`mt-4 text-lg ${status.includes('error') ? 'text-red-500' : 'text-green-500'}`}>
                {status}
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0 flex flex-col items-center">
            <div className="w-48 h-48 flex items-center justify-center mb-8">
              <svg className="w-full h-full" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#FFD700" />
                <path d="M32 12C38.6274 12 44 17.3726 44 24C44 30.6274 38.6274 36 32 36C25.3726 36 20 30.6274 20 24C20 17.3726 25.3726 12 32 12Z" fill="#0084FF" />
                <path d="M32 48C35.3137 48 38 45.3137 38 42C38 38.6863 35.3137 36 32 36C28.6863 36 26 38.6863 26 42C26 45.3137 28.6863 48 32 48Z" fill="#34A853" />
              </svg>
            </div>
            <div className="text-center text-gray-600 space-y-4">
              <div className="flex items-center justify-center space-x-2">
                <span role="img" aria-label="phone">📞</span>
                <span>India: +91 80890 63643</span>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <span role="img" aria-label="email">✉️</span>
                <span>contact@agni.org.in</span>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <span role="img" aria-label="location">📍</span>
                <span>Ground Floor, Pearl Building, Sonoro Church Road, opposite Radisson Blu Hotel, Elamkulam, Kochi, Kerala-682020</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
