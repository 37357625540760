// src/components/AgniLanguageTraining.js
import React from 'react';
import img1 from './../img/learnmore/1.png';

const AgniLanguageTraining = () => {
  return (
    <div className="flex flex-col lg:mx-[10%] p-8">
      <h1 className="lg:text-6xl text-4xl font-bold mb-2">
        Discover <span className="text-yellow-600">Agni Language Training</span>
      </h1>
      <p className=" text-xl mb-6">
        Not Just Educators, But Trailblazers Revolutionizing Language Education
      </p>
      <div className=" verflow-hidden rounded-lg shadow-lg">
        <img
          src={img1} // update with the correct path
          alt="Agni Language Training Team"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default AgniLanguageTraining;
