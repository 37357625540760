import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1 from './../img/languages/german.png';
import img2 from './../img/languages/french.png';
import img3 from './../img/languages/spanish.png';
import img4 from './../img/languages/english.png';
import img5 from './../img/languages/india.png';

const languages = [
  { name: 'German', imageUrl: img1 },
  { name: 'French', imageUrl: img2 },
  { name: 'Spanish', imageUrl: img3 },
  { name: 'English', imageUrl: img4 },
  { name: 'Hindi', imageUrl: img5 },
  { name: 'Sanskrit', imageUrl: img5 }
];

function LanguageSelection() {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleClick = (language) => {
    navigator('/course/' + language);
    setSelectedLanguage(language);
  };

  return (
    <>
      <p className='m-10 lg:text-3xl font-bold text-center'>
        Which language do you want to learn?
      </p>
      <div className="m-10 grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4 py-4">
        {languages.map((language, index) => (
          <div
            key={language.name}
            className="cursor-pointer p-4 border hover:bg-indigo-300 rounded-md text-center bg-white"
            onClick={() => handleClick(language.name)}
            data-aos="zoom-in"
            data-aos-delay={index * 100} // Staggered effect
          >
            <img
              src={language.imageUrl}
              alt={`${language.name} flag`}
              className="mx-auto mb-2 lg:w-20 lg:h-20 w-16 h-16 object-cover"
            />
            <span className="text-gray-700">{language.name}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default LanguageSelection;
