import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image1 from '../img/whychooseus.png';

const WhyChooseUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-white lg:m-10 p-8">
      <h2
        className="text-purple-600 lg:text-6xl text-3xl font-bold mb-2"
        data-aos="fade-up"
      >
        Why Choose Us
      </h2>
      <p
        className="text-gray-600 mb-8 lg:text-2xl"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Master Languages with Expert Guidance and Innovative Techniques
      </p>

      <div className="flex flex-col items-center md:flex-row">
        <div className="flex-1 space-y-6">
          <div className="flex items-start" data-aos="fade-right">
            <div className="text-red-500 lg:text-3xl text-xl">&#10004;</div>
            <div className="ml-4">
              <h3 className="text-xl lg:text-3xl font-semibold">Unparalleled Advantages</h3>
              <p className="text-gray-600 lg:text-2xl">Joining Agni offers unique benefits designed to accelerate your language learning journey.</p>
            </div>
          </div>

          <div className="flex items-start" data-aos="fade-right" data-aos-delay="200">
            <div className="text-red-500 lg:text-3xl text-xl">&#10004;</div>
            <div className="ml-4">
              <h3 className="text-xl lg:text-3xl font-semibold">Efficient and Effective Courses</h3>
              <p className="text-gray-600 lg:text-2xl">Our courses are structured to help you acquire language skills quickly and efficiently.</p>
            </div>
          </div>

          <div className="flex items-start" data-aos="fade-right" data-aos-delay="400">
            <div className="text-red-500 lg:text-3xl text-xl">&#10004;</div>
            <div className="ml-4">
              <h3 className="text-xl lg:text-3xl font-semibold">Internationally Certified Instructors</h3>
              <p className="text-gray-600 lg:text-2xl">Learn from experienced teachers who are certified and recognized internationally.</p>
            </div>
          </div>

          <div className="flex items-start" data-aos="fade-right" data-aos-delay="600">
            <div className="text-red-500 lg:text-3xl text-xl">&#10004;</div>
            <div className="ml-4">
              <h3 className="text-xl lg:text-3xl font-semibold">World-Class Training</h3>
              <p className="text-gray-600 lg:text-2xl">Receive top-tier language education that combines proficiency with cultural understanding.</p>
            </div>
          </div>
        </div>

        <div
          className="flex-1 flex items-center justify-center mt-8 md:mt-0"
          data-aos="fade-left"
        >
          <div className="relative w-full h-full lg:w-[75%] lg:h-[75%]">
            <img
              src={image1}
              alt="People working together"
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
