import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import image2 from './../img/testimonials/1.jpg';
import image3 from './../img/testimonials/2.jpg';
import image4 from './../img/testimonials/3.jpg';
import image5 from './../img/testimonials/4.jpg';
import image6 from './../img/testimonials/5.jpg';
import image7 from './../img/testimonials/7.jpg';





const testimonials = [
  {
    quote: "I learned German at Agni Institute. The classes were very interesting and useful for me especially the exam preparation",
    name: "ALBIN MATHEW",
    title: "STUDENT",
    company: "GERMANY",
    image: image2
  },
  {
    quote: "I am currently working for Igt Dubai in the Lufthansa process, I am very thankful to Arushi Ma’am for helping me improve my pronunciation and speaking skills.",
    name: "SANJAY SHARMA",
    title: "PROCESS ASSOCIATE",
    company: "IGT DUBAI",
    image: image3
  },
  {
    quote: "Life in Germany is easier when you can speak German confidently. I had no idea, where to start. Rajveer Sir guided me through the whole process and I was able to learn German so quickly. Thank you Sir!",
    name: "STEFIA JOSEPH",
    title: "NURSE",
    company: "GERMANY",
    image: image4
  },
  {
    quote: "My time at Agni learning German has been incredibly rewarding, thanks to Rajveer Sir. The institution offers a well-structured curriculum that covers all aspects of language learning — from grammar to conversation skills. The teachers here , at agni , are highly knowledgeable and passionate about teaching German",
    name: "Aashna",
    title: "Mathew",
    company: "",
    image: image5
  },
  {
    quote: "I highly recommend Agni German language Institution to anyone seeking a high-quality education with excellent support services and a vibrant campus life. My time here has been incredibly rewarding",
    name: "Arunima",
    title: "Raghunathan",
    company: "",
    image: image6
  },
  {
    quote: "Great German language learning experience from Agni institute.The way German is taught is exquisite compared to other institutions. Rajveer Sir always listens to our difficulties and helps us to improve. In every week I have learned more and more and it helps me improve my speaking a lot. Thank you so much.",
    name: "Josna",
    title: "Mathew",
    company: "",
    image: image7
  },
  {
    quote: "During my German course , i have got the best guidance. She is so much passionate and her dedication towards teaching is beyond words. Ma’am explains every topic deeply to make students understand in a simple and easy way. Her classes are very much enjoyable because she gives fun activities to do between classes so that students can enjoy and have a small break from these stressful studies.",
    name: "Mahi krishnan",
    title: "",
    company: "",
    image: null
  }
];

const TestimonialSection = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1200 });

    const handleMouseDown = (e) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.isDown = true;
        scrollContainerRef.current.startX = e.pageX - scrollContainerRef.current.offsetLeft;
        scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft;
      }
    };

    const handleMouseLeave = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.isDown = false;
      }
    };

    const handleMouseUp = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.isDown = false;
      }
    };

    const handleMouseMove = (e) => {
      if (!scrollContainerRef.current.isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - scrollContainerRef.current.startX) * 2; // Scroll speed
      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft - walk;
    };

    const scrollContainer = scrollContainerRef.current;

    scrollContainer.addEventListener('mousedown', handleMouseDown);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    scrollContainer.addEventListener('mouseup', handleMouseUp);
    scrollContainer.addEventListener('mousemove', handleMouseMove);

    return () => {
      scrollContainer.removeEventListener('mousedown', handleMouseDown);
      scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
      scrollContainer.removeEventListener('mouseup', handleMouseUp);
      scrollContainer.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="flex flex-col   lg:my-20 my-10 mx-5 bg-white">
      <h1 className="text-3xl font-bold mb-8" data-aos="fade-up">See What Others Are Achieving Through Learning</h1>
      <div ref={scrollContainerRef} className="scroll-container overflow-x-hidden w-full">
        <div className="flex space-x-4 px-4 lg:px-14 snap-x snap-mandatory">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex-none w-3/4 md:w-2/5 lg:w-1/4 bg-gray-100 p-3 py-6 lg:p-6 lg:px-[35px] rounded-lg shadow-md snap-start"
              data-aos="fade-up"
              data-aos-delay={index * 100} // Stagger the animations
            >
              <h1 className="mt-4 text-6xl font-bold">"</h1>
              <blockquote className="mb-4 lg:text-xl text-md font-bold">{testimonial.quote}</blockquote>
              <div className="flex items-center lg:mt-10 space-x-4">
                { testimonial.image && <img
                  src={testimonial.image}
                  className="w-16 h-16 rounded-full object-cover"
                /> }
                <div>
                  <h3 className="font-semibold">{testimonial.name}</h3>
                  <p className="font-semibold">{testimonial.title}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
