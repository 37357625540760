import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import image1 from '../img/readytostart.png';

const ReadyToStart = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (
    <div className="bg-white p-8 rounded-lg flex flex-col md:flex-row items-center">
      <div 
        className="flex-1 mb-4 lg:h-[400px] h-[300px] md:mb-0 md:mr-4" 
        data-aos="fade-right" // Add AOS animation attribute
      >
        <img
          src={image1}
          alt="Person engaged in language learning"
          className="rounded-lg shadow-lg lg:w-[50%] lg:ml-[30%] h-full object-cover"
        />
      </div>
      <div 
        className="flex-1"
        data-aos="fade-left" // Add AOS animation attribute
      >
        <h2 className="text-green-700 text-2xl lg:text-5xl font-bold mb-2">Ready to Start?</h2>
        <p className="text-gray-600 lg:text-3xl lg:mb-7 mb-4">Embark on your language journey with us today!</p>
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <span className="text-purple-600 text-xl lg:text-2xl mr-2">📞</span>
            <span className="lg:text-xl text-gray-800">
              +918089063643 <span className="lg:text-xl text-gray-500">(Available for calls and WhatsApp)</span>
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-purple-600 text-xl lg:text-2xl mr-2">📍</span>
            <span className="text-gray-800 lg:text-xl">
              Come visit us in person from <span className="font-bold">Monday to Friday, between 8 AM and 2 PM</span>, and explore our institute firsthand.
            </span>
          </div>
        </div>
        <a target="_blank" href="https://www.google.com/maps/dir//Ground+Floor,+Pearl+Building+Sonoro,+Fathima+Church+Rd,+opposite+Radission+Blu+Hotel,+Elamkulam,+Kochi,+Ernakulam,+Kerala+682020/@9.9429045,76.2201604,11.63z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b0873bd1682f477:0x3bdf27e83339982!2m2!1d76.3073192!2d9.9665405?entry=ttu"className="bg-orange-500 lg:text-2xl text-white px-6 py-2 lg:w-60 rounded-full flex items-center hover:bg-orange-600 transition-colors">
          Visit Us<span className="ml-2">→</span>
        </a>
      </div>
    </div>
  );
};

export default ReadyToStart;
