import React from 'react';
import img from './../img/learnmore/2.png';
import img1 from './../img/learnmore/5.png';
import img3 from './../img/learnmore/8.png';
import img2 from './../img/learnmore/7.png';


const WhatSetsUsApart = () => {
  return (
    <div className="bg-white lg:mx-[10%] p-6 md:p-12">
      <div className="text-center mb-8">
        <h2 className="text-2xl lg:text-5xl font-bold text-purple-600 mb-4">What Sets Us Apart</h2>
        <p className="text-gray-700 lg:text-2xl">
          At Agni, we believe in experiential learning. Our curriculum is designed to incorporate real-life scenarios,
          cultural immersion, and practical language usage.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-purple-500 text-white p-6 rounded-md">
          <h3 className="text-lg lg:mt-10 font-bold lg:text-5xl mb-4">Interactive Classes with Native Speakers</h3>
          <p className='lg:text-2xl'>
            Our classes are led by native speakers who provide authentic language practice and cultural insights.
            This approach ensures that students not only learn the language but also understand the nuances and
            contexts in which it is used.
          </p>
        </div>
        <div className=" rounded-md">
          <img 
            src={img} 
            alt="Interactive classes" 
            className="w-full h-auto rounded-md"
          />
        </div>
        <div className=" rounded-md">
          <img 
            src={img1} 
            alt="Cultural exchange" 
            className="w-full h-auto rounded-md"
          />
        </div>
        <div className="bg-teal-500 text-white p-6 rounded-md">
          <h3 className="text-lg lg:mt-10 lg:text-5xl lg:text-5xl font-bold mb-4">Cultural Exchange Programs</h3>
          <p className='lg:text-2xl'>

            We organize cultural exchange programs that allow students to immerse themselves in different cultures.
            These programs include virtual exchanges, study abroad opportunities, and participation in cultural events.
          </p>
        </div>
        <div className="bg-purple-500 text-white p-6 rounded-md">
          <h3 className="text-lg lg:mt-10 font-bold lg:text-5xl mb-4">State-of-the-Art Learning Facilities</h3>
          <p className='lg:text-2xl'>
          Our learning centers are equipped with the latest technology and resources to provide a conducive learning environment. This includes multimedia classrooms, language labs, and digital libraries.
          </p>
        </div>
        <div className=" rounded-md">
          <img 
            src={img2} 
            alt="Interactive classes" 
            className="w-full h-auto rounded-md"
          />
        </div>
        <div className="rounded-md">
          <img 
            src={img3} 
            alt="Cultural exchange" 
            className="w-full h-auto rounded-md"
          />
        </div>
        <div className="bg-teal-500 text-white p-6 rounded-md">
          <h3 className="text-lg lg:mt-10 lg:text-5xl lg:text-5xl font-bold mb-4">Customized Learning Paths</h3>
          <p className='lg:text-2xl'>

          We understand that each student has unique learning needs and goals. Our customized learning paths are tailored to meet these individual requirements, ensuring a personalized and effective learning experience.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WhatSetsUsApart;
