import React, { useState } from "react";

import img1 from './../img/imagegallery/1.png';
import img2 from './../img/imagegallery/2.png';
import img3 from './../img/imagegallery/3.png';
import img4 from './../img/imagegallery/4.png';
import img5 from './../img/imagegallery/5.png';
import img6 from './../img/imagegallery/6.png';
import img7 from './../img/imagegallery/7.png';
import img8 from './../img/imagegallery/8.png';
import img9 from './../img/imagegallery/9.png';
import img10 from './../img/imagegallery/10.png';
import img1a from './../img/imagegallery/1.jpg';
import img2a from './../img/imagegallery/2.jpg';
import img3a from './../img/imagegallery/3.jpg';
import img4a from './../img/imagegallery/4.jpg';
import img11 from './../img/imagegallery/11.jpg';
import img12 from './../img/imagegallery/12.jpg';
import img13 from './../img/imagegallery/13.jpg';
import img14 from './../img/imagegallery/14.jpg';
import img15 from './../img/imagegallery/15.jpg';
import img16 from './../img/imagegallery/16.jpg';
import img17 from './../img/imagegallery/17.jpg';
import img18 from './../img/imagegallery/18.jpg';
import img19 from './../img/imagegallery/19.jpg';
import img20 from './../img/imagegallery/20.jpg';

const images = [img1a, img2a,  img4a, img1,  img12, img11, img15, img16, img17, img6, img19, img20,  img14,img10, img18,  img8, img9];

const MobileImageGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="w-full lg:hidden block mb-10 flex flex-col items-center">
      <div className="relative w-full max-w-3xl h-64 overflow-hidden">
        <div
          className="absolute inset-0 flex transition-transform ease-in-out duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full flex-shrink-0 object-cover"
            />
          ))}
        </div>

        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-white/70 rounded-full shadow-lg hover:bg-white focus:outline-none"
        >
          ❮
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-white/70 rounded-full shadow-lg hover:bg-white focus:outline-none"
        >
          ❯
        </button>
      </div>

      <div className="flex space-x-2 mt-4">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileImageGallery;
