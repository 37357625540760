import React from 'react';
import logo from './../img/logo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigator = useNavigate();
  return (
    <footer className="bg-orange-500 text-white py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        <div className="mb-4 md:mb-0 text-center md:text-left">
            
            <img src={logo} alt="Logo" className="h-[100px] w-[100px] mb-2 mx-auto md:mx-0" onClick={() =>navigator('/')} />
          <p className="max-w-xs mx-auto md:mx-0 text-sm md:text-base">
            Our mission is to deliver exceptional language solutions characterized by innovation, excellence, and a deep understanding of diverse linguistic and cultural landscapes.
          </p>
        </div>
        <div className="flex flex-col items-center mb-4 md:mb-0">
          <h3 className="font-bold mb-2 text-lg md:text-xl text-center">Get Started On Your Learning Journey Today</h3>
          <a href="/contact" className="bg-white text-orange-500 px-4 py-2 rounded-full flex items-center">
            <span>Get Started</span>
            <svg className="h-5 w-5 ml-2" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 3a1 1 0 0 1 .7 1.7L5.4 10h9.3a1 1 0 0 1 0 2H5.4l5.3 5.3a1 1 0 0 1-1.4 1.4l-7-7a1 1 0 0 1 0-1.4l7-7A1 1 0 0 1 10 3z" />
            </svg>
          </a>
        </div>
        <div className="text-center md:text-right">
          <div className="flex justify-center md:justify-end mb-4">
            <a href="https://instagram.com" className="mx-2">
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                {/* Instagram SVG path */}
              </svg>
            </a>
            <a href="https://linkedin.com" className="mx-2">
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                {/* LinkedIn SVG path */}
              </svg>
            </a>
            <a href="https://facebook.com" className="mx-2">
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                {/* Facebook SVG path */}
              </svg>
            </a>
          </div>
          <p className="text-sm md:text-base">Contact Us</p>
          <p className="text-sm md:text-base">India: +91 80890 63643
          </p>
          <p className="text-sm md:text-base">Email: contact@agni.org.in          </p>
          <p className="text-sm md:text-base">Ground Floor, Pearl Building, Sonoro Church Road, opposite Radisson Blu Hotel, Elamkulam, Kochi, Kerala-682020</p>
        </div>
      </div>
      <div className="border-t border-orange-700 mt-8 pt-4 text-center">
        <p className="text-sm md:text-base">&copy; 2021</p>
      </div>
    </footer>
  );
};

export default Footer;
