import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from './../img/instagallery/1.jpg';
import img2 from './../img/instagallery/2.jpg';
import img6 from './../img/instagallery/3.jpg';
import img4 from './../img/instagallery/4.jpg';
import img5 from './../img/instagallery/5.jpg';
import img3 from './../img/instagallery/6.jpg';
import img7 from './../img/instagallery/7.jpg';
import img8 from './../img/instagallery/8.jpg';
import img9 from './../img/instagallery/9.jpg';
import img10 from './../img/instagallery/10.jpg';



const InstagramGallery = ({ username }) => {
  const [images, setImages] = useState([]);
  const [visibleCount, setVisibleCount] = useState(9);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1200 });

    // Mock function to simulate fetching data from Instagram
    const fetchInstagramImages = async () => {
      // Replace this with a real API call
      const response = {
        data: [
          { id: 1, media_url: img1, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user1' },
          { id: 2, media_url: img2, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user2' },
          { id: 3, media_url: img3, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user3' },
          { id: 4, media_url: img4, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user4' },
          { id: 5, media_url: img5, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user5' },
          { id: 6, media_url: img6, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user6' },
          { id: 7, media_url: img7, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user7' },
          { id: 8, media_url: img8, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user8' },
          { id: 9, media_url: img9, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user9' },
          { id: 10, media_url: img10, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user10' },
          { id: 11, media_url: img2, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user11' },
          { id: 12, media_url: img3, permalink: 'https://www.instagram.com/agnilanguageservices?igsh=MWwyZHYxbnZhcmc2aQ==', username: 'user12' },
        ],
      };

      setImages(response.data);
    };

    fetchInstagramImages();

    // Check if the screen is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setVisibleCount(window.innerWidth <= 768 ? 3 : 9);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [username]);

  const handleSeeMore = () => {
    setVisibleCount(images.length);
  };

  const handleSeeLess = () => {
    setVisibleCount(isMobile ? 3 : 9);
  };

  return (
    <div className="flex flex-col items-center justify-center py-8">
      <h1 className="text-3xl font-bold mb-4 text-center" data-aos="fade-up">LETS GET SOCIAL
      </h1>
      <p className="mb-8 text-center" data-aos="fade-up" data-aos-delay="200">Join us on Instagram for a behind-the-scenes look at the awesome and exciting world of Agni!</p>
      <div className="grid grid-cols-1 sm:grid-cols-3 w-[85%] gap-4 mb-4">
        {images.slice(0, visibleCount).map((image, index) => (
          <div 
            key={index} 
            className="relative"
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          >
           <a href={image.permalink} target="_blank" rel="noopener noreferrer">
  <img 
    src={image.media_url} 
    alt={`Gallery Image ${index}`} 
    className="w-full h-auto shadow-md transition-transform duration-300 hover:scale-105" 
  />
</a>


            <a href={image.permalink} target="_blank" rel="noopener noreferrer" className="absolute bottom-2 left-2 bg-white bg-opacity-75 p-1 rounded-full">
              <svg className="h-6 w-6 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.17.057 1.97.25 2.42.414.57.2.975.43 1.406.856.43.43.656.837.855 1.405.164.45.357 1.25.414 2.42.059 1.266.071 1.646.071 4.85s-.012 3.584-.071 4.85c-.057 1.17-.25 1.97-.414 2.42-.2.57-.43.975-.855 1.405-.43.43-.837.656-1.406.856-.45.164-1.25.357-2.42.414-1.266.059-1.646.071-4.85.071s-3.584-.012-4.85-.071c-1.17-.057-1.97-.25-2.42-.414-.57-.2-.975-.43-1.405-.855-.43-.43-.656-.837-.856-1.406-.164-.45-.357-1.25-.414-2.42C2.175 15.584 2.163 15.204 2.163 12s.012-3.584.071-4.85c.057-1.17.25-1.97.414-2.42.2-.57.43-.975.855-1.405.43-.43.837-.656 1.405-.856.45-.164 1.25-.357 2.42-.414C8.416 2.175 8.796 2.163 12 2.163m0-2.163C8.695 0 8.288.013 7.052.073 5.82.133 4.959.325 4.2.568 3.414.82 2.765 1.178 2.222 1.722c-.542.543-.901 1.192-1.154 1.978-.243.76-.435 1.62-.495 2.852C.014 8.288 0 8.695 0 12s.013 3.712.073 4.948c.06 1.232.252 2.093.495 2.853.253.785.611 1.434 1.154 1.977.543.544 1.192.902 1.978 1.155.76.243 1.62.435 2.852.495 1.236.06 1.643.073 4.948.073s3.712-.013 4.948-.073c1.232-.06 2.093-.252 2.853-.495.785-.253 1.434-.611 1.977-1.155.544-.543.902-1.192 1.155-1.977.243-.76.435-1.62.495-2.852.06-1.236.073-1.643.073-4.948s-.013-3.712-.073-4.948c-.06-1.232-.252-2.093-.495-2.853-.253-.785-.611-1.434-1.155-1.977-.543-.543-1.192-.901-1.977-1.154-.76-.243-1.62-.435-2.852-.495-1.236-.06-1.643-.073-4.948-.073zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 10.163a3.999 3.999 0 110-7.998 3.999 3.999 0 010 7.998zm6.406-11.845a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z" />
              </svg>
            </a>
          </div>
        ))}
      </div>
      {visibleCount < images.length ? (
        <button onClick={handleSeeMore} className="bg-orange-500 text-white px-4 py-2 rounded-full" data-aos="fade-up" data-aos-delay="300">See more</button>
      ) : (
        <button onClick={handleSeeLess} className="bg-orange-500 text-white px-4 py-2 rounded-full" data-aos="fade-up" data-aos-delay="300">See less</button>
      )}
    </div>
  );
};

export default InstagramGallery;
