import React, { useEffect } from 'react';
import image1 from '../img/dir.png'
import image2 from '../img/dir2.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const directors = [
  {
    name: "Arushi Singh",
    title: "Head Teacher-Trainer\nExecutive Director",
    description: "A Masters in English Literature and a C2 German Certificate from Goethe Institute, brings over a decade of experience in language training across multiple continents. As Executive Director, she ensures excellence in teaching.",
    image: image1, // replace with actual image source
  },
  {
    name: "Rajveer Singh Chouhan",
    title: "Managing Director \n\n",
    description: "A Degree in Finance and C2 German Certificate from Goethe Institute. created our acclaimed B2 Integrated Exam Training Module. As Managing Director, he continuously enhances our curriculum for optimal learning",
    image: image2, // replace with actual image source
  },
];

const MeetOurDirectors = () => {


        useEffect(() => {
          AOS.init({ duration: 1000 });
        }, []);
    
  return (
    <div id="about-us" className="bg-[#eefef8] p-8">
      <h2 className="text-black text-3xl font-bold mb-2 text-center">Meet Our Directors</h2>
      <p className="text-gray-600 mb-8 text-center">Our Directors Leading the Way in Language Learning Excellence</p>
      
      <div className="flex flex-col md:flex-row justify-center items-center md:space-x-8">
        {directors.map((director, index) => (
          <div key={index} className=" lg:w-[40%] h-full rounded-lg  p-6 mb-8 md:mb-0 text-center w-72"   data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}>
            <div className="bg-[#fdf1e2] shadow-lg">
            <img
              src={director.image}
              alt={director.name}
              className="rounded-lg w-[70%] h-[30%] mx-auto mb-4 object-cover"
            />
            <h3 className="text-xl font-bold">{director.name}</h3>
            <p className="text-gray-500 mb-4 font-bold whitespace-pre-line">{director.title}</p>
            </div>
            <p className="text-gray-600 lg:mx-[10%]  text-contain font-bold text-sm">{director.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MeetOurDirectors;
