import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from './../img/imagegallery/1.png';
import img2 from './../img/imagegallery/2.png';
import img3 from './../img/imagegallery/3.png';
import img4 from './../img/imagegallery/4.png';
import img5 from './../img/imagegallery/5.png';
import img6 from './../img/imagegallery/6.png';
import img7 from './../img/imagegallery/7.png';
import img8 from './../img/imagegallery/8.png';
import img9 from './../img/imagegallery/9.png';
import img10 from './../img/imagegallery/10.png';
import img1a from './../img/imagegallery/1.jpg';
import img2a from './../img/imagegallery/2.jpg';
import img3a from './../img/imagegallery/3.jpg';
import img4a from './../img/imagegallery/4.jpg';
import img11 from './../img/imagegallery/11.jpg';
import img12 from './../img/imagegallery/12.jpg';
import img13 from './../img/imagegallery/13.jpg';
import img14 from './../img/imagegallery/14.jpg';
import img15 from './../img/imagegallery/15.jpg';
import img16 from './../img/imagegallery/16.jpg';
import img17 from './../img/imagegallery/17.jpg';
import img18 from './../img/imagegallery/18.jpg';
import img19 from './../img/imagegallery/19.jpg';
import img20 from './../img/imagegallery/20.jpg';

const images = [img1a, img2a, img3a, img4a, img1,  img12, img11, img15, img16, img17, img6, img19, img20, img5, img14,img10, img18, img7, img8, img9];
const PAGE_SIZE = 5; // Number of images per page

const ImageGallery = () => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const totalPages = Math.ceil(images.length / PAGE_SIZE);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = currentPage * PAGE_SIZE;
  const selectedImages = images.slice(startIndex, startIndex + PAGE_SIZE);

  return (
    <div className="flex flex-col items-center justify-center h-auto bg-white py-8">
      <h1 className="text-3xl font-bold mb-4 text-center" data-aos="fade-up">Moments of Learning</h1>
      <p className="mb-8 text-center" data-aos="fade-up" data-aos-delay="200">Experience the vibrant life at Agni Language Institute through our photo gallery.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mb-4 w-[90%] px-4">
        <div className="col-span-1 sm:col-span-2 md:col-span-2">
          <img 
            src={selectedImages[0]} 
            alt={`Gallery Image 1`} 
            className="w-full h-full object-cover rounded-xl lg:rounded-3xl shadow-md" 
            data-aos="fade-right"
            style={{ objectFit: 'cover', height: '500px' }} // Adjust height as needed
          />
        </div>
        {selectedImages.slice(1).map((src, index) => (
          <img 
            key={index + 1} 
            src={src} 
            alt={`Gallery Image ${index + 2}`} 
            className="w-full h-full object-cover rounded-xl lg:rounded-3xl shadow-md"
            data-aos="fade-up"
            data-aos-delay={`${(index + 1) * 100}`}
            style={{ objectFit: 'cover', height: '500px' }} // Adjust height as needed
          />
        ))}
      </div>
      <div className="flex items-center space-x-4">
        <button 
          onClick={handlePreviousPage} 
          className={`p-2 rounded-full ${currentPage === 0 ? 'opacity-50 cursor-not-allowed' : 'bg-gray-200'}`} 
          disabled={currentPage === 0}
        >
          &lt;
        </button>
        <span className="text-xl">{currentPage + 1} / {totalPages}</span>
        <button 
          onClick={handleNextPage} 
          className={`p-2 rounded-full ${currentPage === totalPages - 1 ? 'opacity-50 cursor-not-allowed' : 'bg-gray-200'}`} 
          disabled={currentPage === totalPages - 1}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ImageGallery;
