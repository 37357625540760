import React from 'react';
import img from './../img/learnmore/4.png';

const UniqueSection = () => {
  return (
    <div className="flex flex-col lg:mx-[10%] md:flex-row items-center bg-white p-6 md:p-12">
      <div className="md:w-1/2 p-4">
        <h2 className="lg:text-6xl text-3xl font-bold text-orange-500 mb-4">What Makes Us Unique?</h2>
        <p className="text-gray-700 lg:text-3xl text-lg font-semibold mb-4">
          “What makes learning a language distinct from learning any other subject?”
          This intriguing question often leads people to realize that language learning is a unique endeavor,
          needing special and personalized methods. Agni Language Services celebrates and uses this very
          distinctiveness to redefine how language is learned.
        </p>
      </div>
      <div className="md:w-1/2 p-4">
        <img 
          src={img} 
          alt="Language learning session" 
          className="w-full h-auto rounded-md"
        />
      </div>
    </div>
  );
}

export default UniqueSection;
