import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import BlogScreen from "./components/BlogScreen";
import ContactForm from "./components/ContactForm";
import FeatureGrid from "./components/FeatureGrid";
import Footer from "./components/footer";
import HeroSection from "./components/hero";
import ImageGallery from "./components/ImageGallery";
import InstagramGallery from "./components/InstagramGallery";
import LanguageSelection from "./components/language";
import Navbar from "./components/nav";
import TestimonialSection from "./components/TestimonialSection";
import Course from "./components/course";
import CourseGrid from "./components/coursepage";
import Sidebar from "./components/adminsidebar";
import AdminPanel from "./components/Courses";
import Courses from "./components/Courses";
import Blogs from "./components/Blogs";
import Responses from "./components/Responses";
import { useState } from "react";
import Login from "./components/login";
import BlogFullScreen from "./components/BlogFullScreen";
import BlogDetailScreen from "./components/BlogFullScreen";
import WhyChooseUs from "./components/WhyChooseUs";
import ReadyToStart from "./components/ReadyToStart";
import MeetOurDirectors from "./components/MeetOurDirectors";
import AgniLanguageTraining from "./components/AgniLanguageTraining";
import UniqueSection from "./components/UniqueSection";
import RevolutionaryApproach from "./components/RevolutionaryApproach";
import WhatSetsUsApart from "./components/WhatSetsUsApart";
import PlacementInfo from "./components/PlacementInfo";
import ActionButtons from "./components/action";
import MobileImageGallery from "./components/mobilimagegalley";

function App() {
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'AdminPanel':
        return <AdminPanel />;
      case 'Courses':
        return <Courses />;
      case 'Blogs':
        return <Blogs />;
      case 'Responses':
        return <Responses />;
      default:
        return <AdminPanel />;
    }
  };

  return (
    <Router>
      <div className="min-h-screen text-text overflow-x-hidden">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <HeroSection />
                <LanguageSelection />
                <Course />
                <FeatureGrid />
                <WhyChooseUs />
                <ReadyToStart />
                <MeetOurDirectors />
                <TestimonialSection />
                <div className="hidden lg:block"> {/* Hide on mobile */}
                  <ImageGallery />
                </div>
                <div className="block lg:hidden"> {/* Show on mobile */}
                  <MobileImageGallery />
                </div>
                <BlogScreen />
                <InstagramGallery />
                <ActionButtons />
                <Footer />
              </>
            }
          />
          <Route path="/contact" element={
            <>
              <Navbar />
              <ContactForm />
              <Footer />
            </>
          } />
          <Route path="/learnmore" element={
            <>
              <Navbar />
              <AgniLanguageTraining />
              <UniqueSection />
              <RevolutionaryApproach />
              <WhatSetsUsApart />
              <LanguageSelection />
              <PlacementInfo />
              <Footer />
            </>
          } />
          <Route path="/course" element={
            <>
              <Navbar />
              <CourseGrid />
              <Footer />
            </>
          } />
          <Route path="/course/:categoryParam" element={
            <>
              <Navbar />
              <CourseGrid />
              <Footer />
            </>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="blog/:id" element={<BlogDetailScreen />} />

          <Route path="/admin" element={
            <div className="flex ">
              <Sidebar onSelect={setSelectedComponent} />
              <div className="flex-1 bg-[#fdf1e2] justify-center items-center p-4">
                {renderComponent()}
              </div>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
