import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from './../supabaseClient'; // Import the Supabase client

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const CourseGrid = () => {
  const { categoryParam } = useParams(); // Get the category parameter from the URL
  const defaultCategory = categoryParam ? capitalizeFirstLetter(categoryParam) : 'All';
  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([{ name: 'All' }]);
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory); // Set default category with capitalization
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch courses and categories in parallel
        const [coursesRes, categoriesRes] = await Promise.all([
          supabase.from('courses').select('*'),
          supabase.from('courses').select('category')
        ]);

        if (coursesRes.error) throw new Error('Failed to load courses.');
        if (categoriesRes.error) throw new Error('Failed to load categories.');

        // Extract unique categories and add to the list
        const uniqueCategories = categoriesRes.data
          .map(cat => cat.category)
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(cat => ({ name: cat }));

        // Sort the courses based on Priority (desc) and then alphabetically
        const sortedCourses = coursesRes.data.sort((a, b) => {
          // Handle null priorities by considering them as the least priority
          const priorityA = a.Priority === null ? -Infinity : a.Priority;
          const priorityB = b.Priority === null ? -Infinity : b.Priority;

          if (priorityB !== priorityA) {
            return priorityB - priorityA;
          }
          return a.name.localeCompare(b.name);
        });

        setCourses(sortedCourses);
        setCategories([{ name: 'All' }, ...uniqueCategories]);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Memoize filtered courses to avoid recalculations on every render
  const filteredCourses = useMemo(() => 
    courses.filter(course => 
      course.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedCategory === 'All' || course.category === selectedCategory)
    ), [courses, searchQuery, selectedCategory]);

  const toggleDetails = (id) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold text-center mb-6">
        Explore <span className="text-yellow-500">Courses</span>
      </h2>
      
      {/* Search Input */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search courses..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      {/* Category Filter */}
      <div className="mb-6 flex justify-center space-x-4 flex-wrap">
        {categories.map(category => (
          <button
            key={category.name}
            onClick={() => setSelectedCategory(category.name)}
            className={`px-4 py-2 border rounded-full ${selectedCategory === category.name ? 'bg-orange-500 text-white' : 'text-orange-500 border-orange-500'}`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {loading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course) => (
              <div key={course.id} className="border rounded-lg p-4 flex-shrink-0 relative">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <span className="bg-orange-400 text-white rounded-full px-3 py-1 text-sm font-semibold">⭐ {course.rating}</span>
                  </div>
                  <div className="flex space-x-2">
                    <button>
                      <i className="fas fa-share-alt text-gray-500"></i>
                    </button>
                    <button>
                      <i className="fas fa-heart text-gray-500"></i>
                    </button>
                  </div>
                </div>
                <h2 className="mt-2 text-lg font-bold">{course.name}</h2>
                <p className="mt-1 text-sm text-gray-500">{course.duration}</p>
                <p className="mt-1 text-orange-600 font-bold">₹ {course.price}/-</p>
                <button
                  className="mt-4 bg-orange-500 text-white w-full py-2 px-4 rounded-xl h-15 hover:bg-orange-600"
                  onClick={() => {navigate('/contact')}}
                >
                  Enquire Now 
                </button>
                <button
                  className="mt-2 border border-orange-500 text-orange-500 w-full py-2 px-4 rounded-xl h-15 hover:bg-orange-500 hover:text-white"
                  onClick={() => toggleDetails(course.id)}
                >
                  {showDetails[course.id] ? 'Hide Details' : 'Show Details'} <i className={`fas fa-chevron-${showDetails[course.id] ? 'up' : 'down'}`}></i>
                </button>
                {showDetails[course.id] && (
                  <div className="mt-4 bg-orange-500 text-white p-4 rounded-lg">
                  {course.category == 'German' &&   <p className="mb-2"><i className="fas fa-calendar-alt"></i> Access to Agni library (6+10) weeks</p>}
                    <p className="mb-2"><i className="fas fa-users"></i> {course.number_of_seats} Seats</p>
                    <p className="mb-2"><i className="fas fa-book"></i> {course.weeks} Weeks </p>
                    {course.extra_detail && (
                      <p
                        className="mt-2"
                        style={{
                          maxWidth: '35vh', 
                          overflowWrap: 'break-word', 
                          wordBreak: 'break-word'
                        }}
                      >
                        <i className="fas fa-info-circle"></i> {course.extra_detail}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-center col-span-full text-gray-500">No courses found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CourseGrid;
