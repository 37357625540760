import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from './../img/featuregrid/image.png';

const features = [
  {
    title: "Experienced Instructors",
    description: "Our team of highly qualified and experienced instructors is dedicated to helping you achieve your language goals.",
    bgColor: "bg-[#f2b2a1] lg:pb-20"
  },
  {
    title: "Interactive Learning Methods",
    description: "We utilize modern, interactive teaching methods to make language learning fun and effective.",
    bgColor: "bg-[#65c6bf] lg:pb-20"
  },
  {
    title: "Comprehensive Language Courses",
    description: "Discover our extensive range of language courses, including German, Spanish, and many more.",
    bgColor: "bg-[#a1adf8]",
    rowSpan: "row-span-2",
    image: { img }
  },
  {
    title: "Exam Preparation Resources",
    description: "We offer comprehensive materials, practice tests, and personalized coaching for German B1 and B2 levels, helping you achieve success and reach your certification goals.",
    bgColor: "bg-[#9d57f6] lg:pb-20"
  },
  {
    title: "Personalized Learning Paths",
    description: "At Agni, we recognize that every learner is unique. Our personalized learning paths ensure that your individual needs and goals are met.",
    bgColor: "bg-[#f7ce45] lg:pb-20"
  }
];

const FeatureGrid = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center py-20 lg:px-[18%] bg-teal-50 px-4">
      <h1 className="text-2xl sm:text-3xl font-bold mb-8 text-center">Learning focused on your goals</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 w-full">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`relative p-4 text-white ${feature.bgColor} rounded-lg shadow-md ${feature.rowSpan || ''}`}
            data-aos="fade-up"
            data-aos-delay={index * 100} // Staggered effect
          >
            <h2 className="text-lg sm:text-xl lg:mt-5 lg:text-4xl font-semibold mb-2">{feature.title}</h2>
            <p className="text-xs lg:text-xl lg:mt-5 sm:text-base">{feature.description}</p>
            {feature.image && (
              <img
                src={feature.image.img}
                alt={feature.title}
                className="absolute bottom-4 right-4 lg:w-[40%] h-[50%] w-[80%]"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureGrid;
