// src/PlacementInfo.js
import React from 'react';
import MedicalJobsLogo from './../img/learnmore/6.png'; // Adjust the path to the image

const PlacementInfo = () => {
    return (
      <div className="bg-white lg:mx-[10%] flex flex-col items-center py-10 px-4 sm:px-6 lg:px-8">
        <div className="w-full ">
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="md:w-1/2">
              <h1 className="text-4xl lg:text-6xl font-bold text-orange-600 mb-4">Beyond Language Learning</h1>
              <p className="text-lg lg:text-2xl mb-4">
                Agni Language Services facilitates placements for professionals in fields like healthcare
                and hospitality in Germany, in partnership with "Medical Jobs," a German placement agency.
              </p>
              <p className="text-lg mb-4 lg:text-2xl">
                We help school graduates join vocational training programs in Germany, offering valuable work
                experience.
              </p>
            </div>
            <div className="md:w-1/2 flex justify-center md:justify-end">
              <img src={MedicalJobsLogo} alt="Medical Jobs" className="h-32 lg:h-[400px] lg:w-[90%] w-auto" />
            </div>
          </div>
          <h2 className="text-2xl lg:text-5xl font-bold text-pink-600 mb-4">Success stories of students placed in Germany</h2>
          <blockquote className="text-lg lg:text-3xl italic">
            "We have a group of five students who learned German with us, progressed from A1 to B2 levels in seven
            months, and were subsequently placed in vocational training programs in Germany. Within a year, they have
            been living in Germany, training to become full-time nurses."
          </blockquote>
        </div>
      </div>
    );
  }

export default PlacementInfo;
