import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaPhoneAlt, FaEnvelope, FaGoogle, FaStar } from 'react-icons/fa';

const ActionButtons = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show buttons when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {/* Google Rating Button on the Left */}
      <div className="fixed bottom-8 left-8">
        {isVisible && (
          <a
            href="https://g.co/kgs/5Uuvni5"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 rounded-full bg-yellow-500 text-white hover:bg-yellow-700 transition duration-300 shadow-lg focus:outline-none"
            aria-label="Google Rating"
          >
            <FaGoogle className="mr-2" /> <FaStar className="mr-1" /> 5
          </a>
        )}
      </div>

      {/* Other Action Buttons on the Right */}
      <div className="fixed bottom-8 right-8 flex flex-col space-y-3">
        {isVisible && (
          <>
            <a
              href="tel:+918089063643"
              className="p-3 rounded-full bg-green-500 text-white hover:bg-green-700 transition duration-300 shadow-lg focus:outline-none"
              aria-label="Call"
            >
              <FaPhoneAlt />
            </a>
            <a
              href="mailto:contact@agni.org.in"
              className="p-3 rounded-full bg-red-500 text-white hover:bg-red-700 transition duration-300 shadow-lg focus:outline-none"
              aria-label="Message"
            >
              <FaEnvelope />
            </a>
            <button
              onClick={scrollToTop}
              className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-700 transition duration-300 shadow-lg focus:outline-none"
              aria-label="Scroll to top"
            >
              <FaArrowUp />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ActionButtons;
