import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../img/logo.png';

const Sidebar = ({ onSelect }) => {
  const [selected, setSelected] = useState('Courses');
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('login') == null) {
      navigate('/login');
    }
  }, [navigate]);

  const handleSelect = (section) => {
    setSelected(section);
    onSelect(section);
  };

  return (
    <div className="flex flex-col w-64 h-screen bg-white shadow-md">
      <div className="flex items-center justify-center p-4">
        <img src= {logo} alt="Logo" className="w-16 h-16" />
      </div>
      <nav className="flex flex-col space-y-4 mt-4">
        <button
          className={`flex items-center p-2 mx-4 rounded-md text-white bg-orange-800`}
          
        >
          <span className="flex-1">Admin Panel</span>
          <span className="material-icons">dashboard</span>
        </button>
        <button
          className={`flex items-center p-2 mx-4 rounded-md ${selected === 'Courses' ? 'text-white bg-orange-500' : 'text-black hover:bg-gray-200'}`}
          onClick={() => handleSelect('Courses')}
        >
          <span className="flex-1">Courses</span>
          <span className="material-icons">school</span>
        </button>
        <button
          className={`flex items-center p-2 mx-4 rounded-md ${selected === 'Blogs' ? 'text-white bg-orange-500' : 'text-black hover:bg-gray-200'}`}
          onClick={() => handleSelect('Blogs')}
        >
          <span className="flex-1">Blogs</span>
          <span className="material-icons">article</span>
        </button>
        <button
          className={`flex items-center p-2 mx-4 rounded-md ${selected === 'Responses' ? 'text-white bg-orange-500' : 'text-black hover:bg-gray-200'}`}
          onClick={() => handleSelect('Responses')}
        >
          <span className="flex-1">Responses</span>
          <span className="material-icons">feedback</span>
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
