import React from 'react';
import img from './../img/learnmore/3.png';

const RevolutionaryApproach = () => {
  return (
    <div className="bg-white lg:mx-[10%] p-6 md:p-12">
      <div className="text-center mb-8">
        <h2 className="text-2xl lg:text-5xl font-bold text-green-600 mb-4">Our Revolutionary Approach</h2>
        <p className="text-gray-700 lg:text-3xl mb-4">
          Unlike most subjects, learning a language engages not just the mind but also the senses and emotions.
          It’s a journey through grammar and vocabulary to unlock human expression, build connections, and navigate
          different lands and cultures.
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 p-4">
          <img 
            src={img} 
            alt="Language learning session" 
            className="w-full h-auto rounded-md"
          />
        </div>
        <div className="md:w-1/2 p-4">
          <p className="text-gray-700 lg:text-3xl mb-4">
            <strong>“We break away from traditional teaching methods, offering fast-paced courses from A1 to B2 levels in as little as five months,”</strong>
          </p>
          <p className="text-gray-700 lg:mt-[70px] lg:text-3xl">
            At Agni Language Services, <strong>Rajveer Singh Chouhan</strong>, Managing Director of the institution,
            along with his team of dedicated instructors, customizes German language programs to suit each student’s
            goals and style of learning.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RevolutionaryApproach;
